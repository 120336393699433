// React env
const api = process.env.REACT_APP_METER_API
const version = process.env.REACT_APP_METER_API_VERSION
const url = api + version
const devToolsEnabled = process.env.REACT_APP_DEVTOOLS
const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY

const APP_CONST = {
  api,
  version,
  url,
  devToolsEnabled,
  stripeApiKey
}

export default APP_CONST
