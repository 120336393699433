// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import React, {useEffect, useState} from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements, PaymentRequestButtonElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import APP_CONST from './utils/constants'
import logo from './logo_waave_black.png';
import "./styles.css";

const CARD_OPTIONS = {
    hidePostalCode: true,
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#757575",
            color: "#000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883"
            },
            "::placeholder": {
                color: "#757575"
            }
        },
        invalid: {
            iconColor: "#eb1c26",
            color: "#eb1c26"
        }
    }
};

const CardField = ({ onChange }) => (
    <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
        className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
        type="submit"
        disabled={processing || disabled}
    >
        {processing ? "Processing..." : children}
    </button>
);

const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);

const { url } = APP_CONST

const createPIApiUrl = `${url}/stripe/createPaymentIntent`
const confirmPIApiUrl = `${url}/stripe/confirmQRPaymentIntent`

const queryParams = new URLSearchParams(window.location.search);

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [confirmCardPayment, setConfirmCardPayment] = useState(null);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [amount, setAmount] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: queryParams.get('token') })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement("card").focus();
            return;
        }

        if (cardComplete) {
            setProcessing(true);
        }

        const payload = await stripe.confirmCardPayment(clientSecret,{
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: "Waave Ride",
                },
            },
        });

        setProcessing(false);

        if (payload.error) {
            setError(payload.error);
        } else {
            console.log('success', payload)

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ payment_intent: payload.paymentIntent })
            };
            fetch(confirmPIApiUrl, requestOptions);

            setConfirmCardPayment(payload.paymentIntent);

            redirect()
        }
    };

    const redirect = () => {
        setTimeout(() => {
            window.location.href = 'https://waave.app.link/qrpay';
        }, 10000)
    }

    useEffect( () => {
        //createPaymentIntent on server and retrieve data
        fetch(createPIApiUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.amount) {
                    setAmount(data.amount)
                    setClientSecret(data.client_secret)
                } else {
                    setClientSecret(false)
                }
            });
    }, [])

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }

        const pr = stripe.paymentRequest({
            currency: 'usd',
            country: 'US',
            total: {
                label: 'Waave Ride',
                amount: amount*100
            }
        })

        pr.canMakePayment().then((result) => {
          if (result) {
              setPaymentRequest(pr);
          }
        })

        pr.on('paymentmethod', async (e) => {
            const {client_secret} = await fetch(createPIApiUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: queryParams.get('token') })
            })
                .then(response => response.json())
            const {error, paymentIntent} = await stripe.confirmCardPayment(client_secret, {
                payment_method: e.paymentMethod.id
            }, {handleActions: false})

            if (error) {
                e.complete('fail')
                return
            }
            e.complete('success')

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ payment_intent: paymentIntent })
            };
            fetch(confirmPIApiUrl, requestOptions);

            setConfirmCardPayment(paymentIntent);
        })
    }, [stripe, elements, setError])

    return clientSecret ? (
        confirmCardPayment ? (
        <div className="Result">
            <img className="logo" src={logo} alt="Waave" />
            <div className="ResultTitle" role="alert">
                Payment successful
            </div>
            <div className="ResultMessage">
                Thanks for paying<br />
            </div>
        </div>
    ) : (
        <div className="Result">
            <form className="Form" onSubmit={handleSubmit}>
                <img className="logo" src={logo} alt="Waave" />
                <div className="PayButton">
                    {paymentRequest &&
                        <div>
                            <PaymentRequestButtonElement options={{paymentRequest}} />
                            <div className="cardText">
                                <span>or pay with card</span>
                            </div>
                        </div>
                    }
                </div>

                <fieldset className="FormGroup">
                    <CardField
                        onChange={(e) => {
                            setError(e.error);
                            setCardComplete(e.complete);
                        }}
                    />
                </fieldset>
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
                <SubmitButton processing={processing} error={error} disabled={!stripe}>
                    Pay ${amount}
                </SubmitButton>
            </form>
        </div>
    )) : clientSecret === false ? (
        <div className="Result">
            <img className="logo" src={logo} alt="Waave" />
            <div className="ResultTitle" role="alert">
                Expired URL
                {redirect()}
            </div>
        </div>
    ) : (
        <div className="Result">
            <img className="logo" src={logo} alt="Waave" />
            <div className="ResultTitle" role="alert">
                Loading ...
            </div>
        </div>
    );
};

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
        }
    ]
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const { stripeApiKey } = APP_CONST
const stripePromise = loadStripe(stripeApiKey);

const App = () => {
    return (
        <div className="AppWrapper">
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CheckoutForm />
            </Elements>
        </div>
    );
};

export default App;
